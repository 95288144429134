/* src/Layout.css */
.layout {
    position: relative;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    background-image: url('./assets/background.jpg'); /* Use your image path */
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    z-index: 0;
  }
  
  .layout::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7); /* Semi-transparent black */
    z-index: -1; /* Ensure the overlay is behind the content */
  }
  
  .content {
    position: relative;
    flex: 1;
    
    padding: 20px;
    z-index: 1; /* Ensure content is above the overlay */
  }

  #logo{
    width: 25%;
    margin-left: auto;
    margin-right: auto;
    display: block;
  }
  